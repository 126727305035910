import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Canvas, useFrame } from "@react-three/fiber";
import {Float, Html } from "@react-three/drei";
import { App } from "./App.jsx"
import { Appmov } from "./Appmov.jsx"
import { Vector3 } from 'three'
import { OrbitControls, Environment } from '@react-three/drei'
import Player from '@vimeo/player';

if (screen.width > 1080) {
    document.fonts.ready.then(function () {

    const root = createRoot(document.getElementById("root"));

    root.render(
      <App/>
    );

    });
  }

  if (screen.width < 1080) {
    document.fonts.ready.then(function () {

      const root = createRoot(document.getElementById("root"));
      
      root.render(
        <Appmov/>
      );
  
      });

  }