import * as THREE from "three";
import { useState, useRef, useEffect, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Environment, useGLTF, useProgress } from "@react-three/drei";
import { EffectComposer, N8AO, SSAO } from "@react-three/postprocessing";
import { BallCollider, Physics, RigidBody, CylinderCollider } from "@react-three/rapier";
import {Model1} from "./Model.jsx";
import {Model2} from "./Model2.jsx";
import {Model3} from "./Model3.jsx";
import {Model4} from "./Model4.jsx";
import {Model5} from "./Model5.jsx";
import {Model6} from "./Model6.jsx";
import {Model7} from "./Model7.jsx";
import {Model8} from "./Model8.jsx";
import {Model9} from "./Model9.jsx";
import {ModelP} from "./Model_Posiciones.jsx";
import {ModelP1} from "./Model_Ponente1.jsx";
import {ModelP2} from "./Model_Ponente2.jsx";
import {ModelP3} from "./Model_Ponente3.jsx";
import {ModelP4} from "./Model_Ponente4.jsx";
import {ModelP5} from "./Model_Ponente5.jsx";
import {ModelP6} from "./Model_Ponente6.jsx";
import {ModelP7} from "./Model_Ponente7.jsx";
import {ModelP8} from "./Model_Ponente8.jsx";
import {ModelP9} from "./Model_Ponente9.jsx";
import { gsap } from "gsap/dist/gsap";

//THREE.ColorManagement.legacyMode = false;

function Loader() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  

  useEffect(() => {

    active ? '' : setTimeout(function () {

    

      preloader.classList.add('trans');
      setTimeout(() => {
      preloader.classList.add('off');
      }, 100);
      setTimeout(() => {
       root.classList.add('on');
        }, 3000);

    }, 0);

  }, [loaded, progress])
}

function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef();

  useFrame(({ mouse, viewport }) => {
    vec.lerp(
      new THREE.Vector3((mouse.x * viewport.width) / 2, (mouse.y * viewport.height) / 2, 0),
      0.2
    );
    ref.current?.setNextKinematicTranslation(vec);
  });

  return (
    <RigidBody position={[100, 100, 100]} type="kinematicPosition" colliders={true} ref={ref}>
      <BallCollider args={[2]} />
    </RigidBody>
  );
}

function Central({ vec = new THREE.Vector3() }) {
  const ref = useRef();
  const [numero, setNumero] = useState(1);

  useEffect(() => {
    const handleClickStartButton = () => {
      var elemento = document.getElementById('startButton');
      if (!elemento.classList.contains('final')) {
      setTimeout(() => {
      const targetValue = 40;
      animateNumero(targetValue);
    }, 3000);
    }
    };
    
    const handleClickVolver = () => {
      setTimeout(() => {
      const targetValue = 1;
      animateNumero(targetValue);
    }, 300);
    };
    
    const animateNumero = (targetValue) => {
      const steps = 100;
      const intervalTime = 2;
      const stepValue = (targetValue - numero) / steps;
      let step = 0;
    
      const intervalId = setInterval(() => {
        setNumero((prevNumero) => prevNumero + stepValue);
        step++;
        if (step >= steps) {
          clearInterval(intervalId);
          setNumero(targetValue);
        }
      }, intervalTime);
    };
    
    document.getElementById('startButton').addEventListener("click", handleClickStartButton);
    document.querySelectorAll('.volver').forEach(item => {
      item.addEventListener('click', handleClickVolver);
    });
    
    return () => {
      document.getElementById('startButton').removeEventListener("click", handleClickStartButton);
      document.querySelectorAll('.volver').forEach(item => {
        item.removeEventListener('click', handleClickVolver);
      });
    };
    

  }, []);

  return (
    <RigidBody position={[0, 0, 0]} type="kinematicPosition" colliders={true} ref={ref}>
      <BallCollider args={[numero]} key={numero} />
    </RigidBody>
  );
}

export const App = () => (
  <Canvas
    shadows
    gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
    camera={{ position: [0, 0, 20], fov: 32.5, near: 1, far: 100 }}
    onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}
  >
    <Suspense fallback={<Loader />}>
      <group>
   <Environment files={"./gym_entrance_4k.hdr"} blur={0} intensity={0}/>
   
{/* <ModelP/> */}

    <ModelP1/>
    <ModelP2/>
   <ModelP3/>
   
   <ModelP4/>
   <ModelP5/>

   <ModelP6/>
   <ModelP7/>
   <ModelP8/>
   <ModelP9/>
      
    <Physics gravity={[0, 0, 0]}>
      <Central/>
      <Pointer />

      <Model1/>
      <Model2/>
      <Model3/>
      <Model4/>
      <Model5/>
      <Model6/>
      <Model7/>
      <Model8/>
      <Model9/>
      <Model1/>
      <Model2/>
      <Model3/>
      <Model4/>
      <Model5/>
      <Model6/>
      <Model7/>
      <Model8/>
      <Model9/>

    </Physics>
    <EffectComposer disableNormalPass multisampling={0}>
      <N8AO color="black" aoRadius={2} intensity={1} />
    </EffectComposer>
    </group>
    </Suspense>
  </Canvas>
);
