import "./styles.css";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";
import Swiper from 'swiper';
import 'swiper/css';
import Player from '@vimeo/player';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);


  document.fonts.ready.then(function () {


    coleccion.addEventListener("click", () => {
      if (coleccion.classList.contains('on')) {
        coleccionpanel.classList.remove("on");
        coleccion.classList.remove("on");
      }else{
        coleccionpanel.classList.add("on");
        coleccion.classList.add("on");
      }
    });

    programa.addEventListener("click", () => {
      if (programa.classList.contains('on')) {
        programacontainer.classList.remove("on");
        programa.classList.remove("on");
      }else{
        programacontainer.classList.add("on");
        programa.classList.add("on");
      }
    });

    colecclose.addEventListener("click", () => {
      coleccionpanel.classList.remove("on");
      coleccion.classList.remove("on");
    });

    var blopElements = document.querySelectorAll('.blop');

  // Para cada elemento con la clase 'blop', agregar un event listener para el evento 'mouseover'
  blopElements.forEach(function(element) {
    var audio = new Audio('./hov.mp3');
    var audio2 = new Audio('./ver.mp3');
    
    element.addEventListener('mouseenter', function() {
      // Crear un nuevo objeto de audio y reproducir el sonido
      audio.play();
    });
    element.addEventListener('mouseleave', function() {
      // Crear un nuevo objeto de audio y reproducir el sonido
      audio2.play();
    });
  });

  let contador = 0;

  function coumun() {
    var countdown = new Audio('./cuenta_atras3.mp3');
    var plop = new Audio('./pop-bub.mp3');
    var fiu = new Audio('./fiu.mp3');
    eti1.classList.add("off");
    eti2.classList.add("off");
    setTimeout(() => {
      fiu.play();
      }, 2800);
    setTimeout(() => {
    plop.play();
    }, 3700);
    setTimeout(() => {
    countdown.play();
  }, 0);
  }

  // Define tus 10 funciones
  function function1() {
    console.log("Plácida!");

    startButton.classList.add("func1");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      placida.classList.add("on");
      placida_carta.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("naranja");
    }, 3000);

    coumun();

  }

  function function2() {
    console.log("Andrés Pachón!");

    startButton.classList.add("func2");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      andrespachon_carta.classList.add("on");
      andrespachon.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("azuloscuro");
    }, 3000);

    coumun();

  }

  function function3() {
    console.log("Arquimaña!");

    startButton.classList.add("func3");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      arquimana_carta.classList.add("on");
      arquimana.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("verde");
    }, 3000);

    coumun();

  }

  function function4() {
    console.log("Pablo Amargo!");

    startButton.classList.add("func4");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      pablo_carta.classList.add("on");
      pablo.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("azulclaro");
    }, 3000);

  coumun();

  }

  function function5() {
    console.log("Takk!");

    startButton.classList.add("func5");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      takk_carta.classList.add("on");
      takk.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("azulclaro");
    }, 3000);
  
    coumun();

  }

  function function6() {
    console.log("Ivan Royo!");

    startButton.classList.add("func6");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      ivanroyo_carta.classList.add("on");
      ivanroyo.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("azuloscuro");
    }, 3000);
  
    coumun();

  }

  function function7() {
    console.log("Elisa Sanz!");

    startButton.classList.add("func7");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      elisasanz_carta.classList.add("on");
      elisasanz.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("naranja");
    }, 3000);
  
    coumun();

  }

  function function8() {
    console.log("Ikerne Jiménez!");

    startButton.classList.add("func8");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      ikerne_carta.classList.add("on");
      ikerne.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("verde");
    }, 3000);
  
    coumun();

  }

  function function9() {
    console.log("Páginas en Construccion!");

    startButton.classList.add("func9");
    count.classList.add("on");
    tres.classList.add("on");
    logo.classList.add("off");
    startButton.classList.add("off");
    
    setTimeout(() => {
      dos.classList.add("on");
      tres.classList.remove("on");
      background.classList.add("on");
    }, 1000);

    setTimeout(() => {
      uno.classList.add("on");
      dos.classList.remove("on");
      
    }, 2000);

    setTimeout(() => {
      contador++;
      uno.classList.remove("on");
      paginas_carta.classList.add("on");
      paginas.classList.add("on");
      document.querySelector('#counter span').innerText = contador;
      count.classList.remove("on");
      backgroundcolor.classList.add("azuloscuro");
    }, 3000);
  
    coumun();

  }

  // Crea un array con las funciones
  const functionsArray = [
   function1,  function2,  function3,  function4,  function5,  function6,  function7,  function8,  function9
  ];

  // Contador de veces que se ha pulsado el botón
  let buttonClickCount = 0;

  // Función para ejecutar una función aleatoria del array
  function executeRandomFunction() {

    if (functionsArray.length === 1) {
      console.log('¡Se ejecutó la última función!');
      const elementosConClase = document.querySelectorAll('.volver');
      // Iterar sobre cada elemento y agregar una nueva clase
      elementosConClase.forEach(function(elemento) {
        elemento.classList.add('ultima');
      });

      const randomIndex = Math.floor(Math.random() * functionsArray.length);
    const randomFunction = functionsArray[randomIndex];
    randomFunction(); // Ejecuta la función aleatoria
    functionsArray.splice(randomIndex, 1); // Elimina la función ejecutada del array
    buttonClickCount++; // Incrementa el contador de pulsaciones de botón
    }
    else{
    const randomIndex = Math.floor(Math.random() * functionsArray.length);
    const randomFunction = functionsArray[randomIndex];
    randomFunction(); // Ejecuta la función aleatoria
    functionsArray.splice(randomIndex, 1); // Elimina la función ejecutada del array
    buttonClickCount++; // Incrementa el contador de pulsaciones de botón
    programacontainer.classList.remove("on");
    programa.classList.remove("on");
    }
  }

  // Evento clic para el botón de inicio
  document.getElementById('startButton').addEventListener('click', executeRandomFunction);
});

if (screen.width < 1080) {


}