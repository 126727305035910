import React, { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { useGLTF, useVideoTexture, MeshReflectorMaterial,useAnimations, MeshTransmissionMaterial } from "@react-three/drei";
import { BallCollider, Physics, RigidBody, CylinderCollider } from "@react-three/rapier";
import * as THREE from 'three'

export function Model9(props) {
  const { nodes } = useGLTF("/bola_1.glb");
  const { nodes: nodes2, materials } = useGLTF("/modelos/comb_c.glb");
  const api = useRef();

  const vec = new THREE.Vector3();

const solidMaterial = new THREE.MeshStandardMaterial({
  color: 0x0171d8,
  roughness: .1,
  metalness: 0,
  side: THREE.DoubleSide,
});

  const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    opacity: 1,
    transparent: true,
    roughness: 0,
    metalness: 0,
    transmission: .9,
    reflectivity: 1,
    side: THREE.DoubleSide,
  });

  const solidWhite = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: .1,
    metalness: 0,
    side: THREE.DoubleSide,
  });

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta);
    if (api.current) {
      const power = 7
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiply({ x: -150 * delta  * power, y: -250 * delta * power, z: -150 * delta * power })
      );
    }
  });

  return (
    <group scale={1.5} position={[0, 20, 0]}>
      <RigidBody
        linearDamping={0.75}
        angularDamping={0.15}
        friction={0.2}
        ref={api}
        colliders={false}
        dispose={null}
      >
        <BallCollider args={[1.02]}/>
        <group position={[0,.1,0]} scale={.05} rotation={[0,0,2]}>
        <mesh
        castShadow
        receiveShadow
        geometry={nodes2.HairComb6.geometry}
        material={solidWhite}
      />
        </group>
        <group scale={.1}>
        <mesh
          material={glassMaterial}
          castShadow
          receiveShadow
          geometry={nodes.transparente.geometry}
        />
        <mesh
          material={solidMaterial}
          castShadow
          receiveShadow
          geometry={nodes.color.geometry}
        />
        </group>
      </RigidBody>
    </group>
  );
}

useGLTF.preload("/ball2.glb");