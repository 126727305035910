import React, { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { useGLTF, useVideoTexture, MeshReflectorMaterial,useAnimations, MeshTransmissionMaterial, Float } from "@react-three/drei";
import { BallCollider, Physics, RigidBody, CylinderCollider } from "@react-three/rapier";
import * as THREE from 'three'
import { gsap } from "gsap/dist/gsap";
import { useControls } from "leva";

export function ModelP(props) {
  const { nodes } = useGLTF("/bola_1.glb");
  const { nodes: nodes2, materials } = useGLTF("/manequin.glb");
  const api = useRef();
  const ponente = useRef();

  const tapa1 = useRef();
  const tapa2 = useRef();

  const vec = new THREE.Vector3();


const solidMaterial = new THREE.MeshStandardMaterial({
  color: 0xff6600,
  roughness: .1,
  metalness: 0,
  side: THREE.DoubleSide,
});

  const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    opacity: 1,
    transparent: true,
    roughness: 0,
    metalness: 0,
    transmission: .9,
    reflectivity: 1,
    side: THREE.DoubleSide,
  });

  const solidWhite = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: .1,
    metalness: 0,
    side: THREE.DoubleSide,
  });

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta);
    if (api.current) {
      const power = 7
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiply({ x: -150 * delta  * power, y: -250 * delta * power, z: -150 * delta * power })
      );
    }
  });

  useEffect(() => {

    ponente.current.position.y = 0.8;

    

  }, []);

  const { positiont1, rotationt1, positiont2, rotationt2 } = useControls({
    positiont1 :
    {
       value: { x: 0, y:0, z:0},
        min: - 15,
        max: 15,
        step: 0.1
    },
    rotationt1:{
      value: { x: 0, y:0, z:0},
        step: 0.01
    },
    positiont2 :
    {
       value: { x: 0, y:0, z:0},
        min: - 15,
        max: 15,
        step: 0.1
    },
    rotationt2:{
      value: { x: 0, y:0, z:0},
        step: 0.01
    }
})


  return (
    <group scale={2.5} position={[0,-10,0]} ref={ponente}>
      <Float
      speed={3} // Animation speed, defaults to 1
      rotationIntensity={2} // XYZ rotation intensity, defaults to 1
      floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
      >
        <group position={[0,-.2,0]} scale={.002}>
        <mesh
        castShadow
        receiveShadow
        geometry={nodes2.Mannequin_cinema_4dobj.geometry}
        material={solidWhite}
      />
        </group>
        </Float>
        <group scale={.1}>
        <mesh
          position={ [positiont1.x, positiont1.y, positiont1.z]}
          rotation={ [rotationt1.x, rotationt1.y, rotationt1.z]}
          ref={tapa1}
          material={glassMaterial}
          castShadow
          receiveShadow
          geometry={nodes.transparente.geometry}
        />
        <mesh
           position={ [positiont2.x, positiont2.y, positiont2.z]}
           rotation={ [rotationt2.x, rotationt2.y, rotationt2.z]}
          ref={tapa2}
          material={solidMaterial}
          castShadow
          receiveShadow
          geometry={nodes.color.geometry}
        />
        </group>
    </group>
  );
}

useGLTF.preload("/ball2.glb");