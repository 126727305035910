import React, { useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { useGLTF, useVideoTexture, MeshReflectorMaterial,useAnimations, MeshTransmissionMaterial, Float } from "@react-three/drei";
import { BallCollider, Physics, RigidBody, CylinderCollider } from "@react-three/rapier";
import * as THREE from 'three'
import { gsap } from "gsap/dist/gsap";
import { useControls } from "leva";

export function ModelP2(props) {
  const { nodes } = useGLTF("/bola_1.glb");
  const { nodes: nodes2, materials } = useGLTF("/modelos/mascara_c.glb");
  const api = useRef();
  const ponente = useRef();

  const tapa1 = useRef();
  const tapa2 = useRef();

  const vec = new THREE.Vector3();


const solidMaterial = new THREE.MeshStandardMaterial({
  color: 0x0171d8,
  roughness: .1,
  metalness: 0,
  side: THREE.DoubleSide,
});

  const glassMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    opacity: 1,
    transparent: true,
    roughness: 0,
    metalness: 0,
    transmission: .9,
    reflectivity: 1,
    side: THREE.DoubleSide,
  });

  const solidWhite = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: .1,
    metalness: 0,
    side: THREE.DoubleSide,
  });

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta);
    if (api.current) {
      const power = 7
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiply({ x: -150 * delta  * power, y: -250 * delta * power, z: -150 * delta * power })
      );
    }
  });

  useEffect(() => {

    //ponente.current.position.y = 0.8;

    
    const body = document.querySelector('body');
    var startButton = document.getElementById("startButton");

    startButton.addEventListener('click', () => {

        if (startButton.classList.contains("func2")) {
            console.log("El botón tiene la clase 'func2'");
            setTimeout(() => {
            gsap.to(ponente.current.position, {
              ease: "power4.Out",
              x: 0,
              z: 0,
              y: .8,
              duration: .4,
           });
           gsap.to(ponente.current.rotation, {
            ease: "power4.Out",
            x: 0.1,
            z: 0.3,
            y: 0.2,
            duration: 1,
         });
        }, 3000);
        setTimeout(() => {
          gsap.to(tapa1.current.rotation, {
            ease: "power4.Out",
            x: 0.46,
            y: -0.45,
            z: 1.17,
            duration: 1,
         });
         gsap.to(tapa1.current.position, {
          ease: "power4.Out",
          x: -5.3,
          y: 5.8,
          z: 0,
          duration: 1,
        });
          gsap.to(tapa2.current.rotation, {
            ease: "power4.Out",
            x: 0.74,
            y: -0.08,
            z: 0.3,
            duration: 1,
         });
         gsap.to(tapa2.current.position, {
          ease: "power4.Out",
          x: 4.6,
          y: -5.9,
          z: -2.8,
          duration: 1,
       });
        }, 3800);

        } else {
            console.log("El botón NO tiene la clase 'func2'");
            gsap.to(ponente.current.position, {
              ease: "power4.Out",
              x: 0,
              z: 0,
              y: -10,
              duration: .4,
           });
           gsap.to(ponente.current.rotation, {
            ease: "power4.Out",
            x: 0,
            z: 0,
            y: 0,
            duration: 1,
         });
        }

    });

    const volver = document.querySelector('#andrespachon .volver');
    volver.addEventListener('click', () => {
      var fiu = new Audio('./fiu.mp3');
      setTimeout(() => {
        fiu.play();
      }, 900);
        gsap.to(tapa1.current.rotation, {
          ease: "power4.Out",
          x: 0,
          y: 0,
          z: 0,
          duration: .6,
       });
       gsap.to(tapa1.current.position, {
        ease: "power4.Out",
        x: 0,
        //y: 0,
        z: 0,
        duration: .6,
      });
        gsap.to(tapa2.current.rotation, {
          ease: "power4.Out",
          x: 0,
          y: 0,
          z:0,
          duration: .6,
       });
       gsap.to(tapa2.current.position, {
        ease: "power4.Out",
        x: 0,
        //y: 0,
        z: 0,
        duration: .6,
      });


    gsap.to(ponente.current.rotation, {
      ease: "power4.Out",
      x: 0,
      z: 0,
      y: 0,
      duration: .9,
   });

       setTimeout(() => {
       gsap.to(tapa2.current.position, {
        ease: "power4.Out",
        y: 0,
        duration: .3,
     });
     gsap.to(tapa1.current.position, {
      ease: "power4.Out",
      y: 0,
      duration: .3,
    });
  
      }, 600);
      setTimeout(() => {

        gsap.to(ponente.current.position, {
          ease: "power4.Out",
          x: 0,
          z: 0,
          y: -10,
          duration: .6,
       });

      backgroundcolor.classList.remove("azuloscuro");
      background.classList.remove("on");
      andrespachon.classList.remove("on");
       boton.classList.remove("func2");
       eti1.classList.remove("off");
       eti2.classList.remove("off");

      }, 1200);
      var logo = document.getElementById("logo");
      var boton = document.getElementById("startButton");

      if (volver.classList.contains('ultima')) {
        var winner = new Audio('./winner.mp3');
        setTimeout(() => {
        var final = document.getElementById("final");
        
        final.classList.add("on");
        var backgroundcolor = document.getElementById("backgroundcolor");
          backgroundcolor.classList.add("final");
          winner.play();
      }, 2000);
      } else{
        setTimeout(() => {
          
        logo.classList.remove("off");
        boton.classList.remove("off");
      }, 2000);
      }

    });

  }, []);

  return (
    <group scale={2.5} position={[0,-10,0]} ref={ponente}>
      <Float
      speed={3} // Animation speed, defaults to 1
      rotationIntensity={2} // XYZ rotation intensity, defaults to 1
      floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
      >
         <group position={[0,-0.1,0.5]} scale={.06}>
        <mesh
        castShadow
        receiveShadow
        geometry={nodes2.faceMesh.geometry}
        material={solidWhite}
      />
        </group>
        </Float>
        <group scale={.1}>
        <mesh
          ref={tapa1}
          material={glassMaterial}
          castShadow
          receiveShadow
          geometry={nodes.transparente.geometry}
        />
        <mesh
          ref={tapa2}
          material={solidMaterial}
          castShadow
          receiveShadow
          geometry={nodes.color.geometry}
        />
        </group>
    </group>
  );
}

useGLTF.preload("/ball2.glb");